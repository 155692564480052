import React from 'react'
import './Admissions2025_26.css'

const Admissions2025_26 = () => {
  return (
<div className="dpsru-every-component-css">
      {/* <div className="admission-flex-container-main">
        <div className="admissions-container-each inner-container"> */}
          <h2 className="dpsru-primary-heading-for-every-page">ADMISSIONS 2025-26</h2>

<h1 className="temp-text-addmission-26">
Information will be shared soon.
</h1>


          {/* <ul>

        
                    <li>
                      <a
                        href='/'
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                   --
                       
                      </a>
                    </li>
                    </ul> */}
                    
    {/* </div>
    </div> */}
    </div>
  )
}

export default Admissions2025_26
