import React, { useState } from "react";
import { BiX } from "react-icons/bi";
import { VscMenu } from "react-icons/vsc";
import "./Nav.css";
import "./ResponsiveNav.css";
import img1 from "../Logo/img 1.1.png";
import img2 from "../Logo/img1.2.png";
import img3 from "../Logo/img 1.3.png";
import img4 from "../Logo/1.4.png";
import img5 from "../Logo/middle img.png";
import img6 from "../Logo/img 2.1.png";
import img7 from "../Logo/img 2.2.png";
import img8 from "../Logo/img 2.3.png";
import img9 from "../Logo/img 2.4.png";
import { NavLink, Link } from "react-router-dom";

import academicSkillHub from "./Acadmics-sec-pdf-Skill-Hub-Inititative-under-PMKVY-4.0-in-DPSRU.pdf";
// import transeferOrder from "./Transfer Order.pdf";
// import revisedAdmissionScheduleofUgCourses2425 from '../Admissions/newData Of admissions/REVISED ADMISSION SCHEDULE OF UG COURSES 2024-25.pdf'


import naacAccreditationgradeseetjuly24 from "../Accreditations & Approvals/NAAC Accreditation Grade Sheet July 2024.pdf";
import academicCalender2425 from "../Academics/Academic calender-2024-25.pdf";



import newblink from "../NEW/new.gif";
import { FaSearch, FaHome } from "react-icons/fa";

// Slider
// Slider

import Carousel from "nuka-carousel";

function Nav() {
  const [respNavStatus, setRespNavStatus] = useState(false);
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];

  return (
    <div>
      <header className="nav-header">
        {/* upper header */}
        <div className="middle-header">
          <div className="upper-header-with-logo">
            <div className="middle-img-section">
              <Link to="/">
                <img className="middle-img" src={img5} alt="" />
              </Link>
            </div>

            <div className="middle-txt-section"></div>
            <div className="last-image-header-crousel">
              {/* renderBottomCenterControls={false} */}
              {/* withoutControls={true} */}
              <Carousel
                autoplay="true"
                // animation="zoom"
                animation="fade"
                pauseOnHover="true"
                breakPoints={breakPoints}
                wrapAround={true}
                withoutControls={true}
                autoplayInterval={5000}
              >
                <Link to="/dipsar">
                  <img src={img8} alt="logo" />
                </Link>
                <Link to="/school-of-physiotherapy">
                  <img src={img2} alt="logo" />
                </Link>
                <Link to="/wcsc">
                  <img src={img3} alt="logo" />
                </Link>
                {/* <Link to='/wcsc'>
                <img src={img4} alt="logo" />
                </Link> */}
                <Link to="/sahsm">
                  <img src={img1} alt="logo" />
                </Link>
                <Link to="/">
                  <img src={img6} alt="logo" />
                </Link>
                <a href="https://diif.in/">
                  <img src={img7} alt="logo" />
                </a>

                <Link to="/">
                  <img src={img9} alt="logo" />
                </Link>
              </Carousel>
            </div>
          </div>

          {/* Navigation  */}

          <div className="bottom-nav main-bottom-nav-for-hide-in-responsive">
            <nav className="navbar">
              <ul className="bottom-menu-ul">
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first bottom-menu-navLink-first-home-icon"
                    to="/"
                  >
                    <FaHome />
                  </NavLink>
                </li>
                <li className="bottom-menu-navLink-first">
                  <NavLink
                    to="/aboutUs"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    {" "}
                    About us
                  </NavLink>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/rd">
                    R&D
                  </NavLink>
                </li>

                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/academics"
                  >
                    Academics
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Schools
                      </NavLink>
                      <ul>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/dipsar"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Delhi Institute of Pharmaceutical Sciences and
                            Reserch
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/soPharmaceticalSciences"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Pharmaceutical Sciences
                          </NavLink>
                          <ul style={{ left: "100%", width: "80px" }}>
                            <li className="inner-li-of-school-inside-of-academics">
                              {" "}
                              <NavLink
                                to="/ayurveda"
                                className="sub-navlink-first bottom-menu-navLink-first"
                              >
                                Ayurveda
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/sahsm"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Allied Health Sciences and Management
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/school-of-physiotherapy"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Physiotherapy
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/doctoralPrograms"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Doctoral Programmes
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Centres
                      </NavLink>
                      <ul>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                          <NavLink
                            to="/wcsc"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            World Class Skill Center
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                        </li>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                          <NavLink
                            to="/assrm"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            ASSRM
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/centresOfExcellence"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Centres of Excellence
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/certificateCourses"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Certificate Courses
                      </NavLink>
                    </li>

                    <li>
                      <a
                        href={academicSkillHub}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Skill Hub PMKVY 4.0
                      </a>
                    </li>
                    <li>
                      <a
                        href={academicCalender2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Academic calender-2024-25
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/administration"
                  >
                    Administration
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink
                        to="/genralCounsil"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Governing Board
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/staff"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Administrative Staff
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/organogram"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Organogram
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/committees"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Committees
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/"
                  >
                    Admissions 
                  </NavLink>
                  <ul style={{width:'195px'}}>
                    <li>
                      <NavLink
                        to="/admissions2025_26"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                         Admissions 2025-26
                      </NavLink>
                    </li> <hr />
                    <li>
                      <NavLink
                        to="/admissions2024_25"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                    Admissions 2024-25
                      </NavLink>
                    </li>
                  </ul>

                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/students">
                    Students
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/scholarShips"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Scholarships
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/hostel"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Hostel
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/examination"
                  >
                    Examinations
                  </NavLink>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first">IQAC</NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/iaqcIntroduction"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Introduction
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacMoM"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        IQAC Committee (Composition and MoM)
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/QualityInitiativeIAQC"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Quality Initiatives
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/NationalRankingIAQC"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        National Rankings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/International-Accolades"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        International Accolades
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iaqcFeedback"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Feedback
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacEvent"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        IQAC Events
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacPolicy"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contactUs"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first">
                    Accreditations & Approvals
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/naac"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NAAC
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href={naacAccreditationgradeseetjuly24}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NAAC Accreditation Grade Sheet July 2024
                      </a>
                    </li>
                    <li>
                      <NavLink
                        to="/nirf"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NIRF
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/atalRanking"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Atal Rankings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/approvals"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Approvals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/ugc_12b"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        UGC 12B
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/">
                    RTI
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/rtiAct"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        RTI Act
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="sub-navlink-first bottom-menu-navLink-first">
                        Section (4)(1)(b) of RTI Act
                      </NavLink>
                      <ul>
                        <li>
                          <NavLink
                            to="/organizationAndFunction"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Organization and Function
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/budgetAndProgramme"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Budget and Programme
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/ppiRti"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Publicity and Public Interface
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/eGovernance"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            E-Governance
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/informationAsMayBePrescribed"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Information as may be prescribed
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/informationDisclosedOnOwnInitiative"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Information Disclosed on own Initiative
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/annualReportOfRti"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Annual Report of RTI
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/cpioAndFaa"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        CPIO and FAA
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* Events  */}
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/events
                    "
                  >
                    Events
                  </NavLink>

                  {/* <ul>
                    <li>
                      <NavLink
                        to="/conference"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Conference
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/ucps"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Seminar
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/workshop"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Workshop
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/g20"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        G20
                      </NavLink>
                      <ul>
                        <li>
                          {" "}
                          <NavLink
                            to="/g20PanelDiscussionReport"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Panel Discussion
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/indoBrazilFusionFest"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Indo Brazil Fusion Fest
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/pushpPradarshnam"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Pushp Pradarshnam 2023
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/G20MahaFoodFestival"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            G20 maha food festival
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/g20QuizCompetition"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            G20 Quiz Competition at DPSRU
                          </NavLink>
                        </li>
                        <li>
                          {" "}
                          <NavLink
                            to="/g20"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            G20 Cycle Rally at 'Qutub Minar'
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/nationalScinceDay"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        National Science Day
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/unnatBharatAbhiyan"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Unnat Bharat Abhiyan, DPSRU
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/Swachhta-Hi-seva"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Swachhta Hi seva – Ek Tareek- Ek Ghante at DPSRU
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/world-heart-day-29-sept"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        World Heart Day (29th September)
                      </NavLink>
                    </li>

                    <li className="bottom-menu-first-li">
                      <NavLink
                        className="bottom-menu-navLink-first"
                        to="/chintan-shivir"
                      >
                        CHINTAN SHIVIR
                      </NavLink>
                    </li>
                  </ul> */}
                </li>
                {/* Events  */}
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/campusAmenities"
                  >
                    Campus Amenities
                  </NavLink>
                </li>

                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/grievanceForm"
                  >
                    Grievance Form
                  </NavLink>
                </li>
                {/* <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/dpsruLibrary"
                  >
                    Library
                  </NavLink>
                </li> */}
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="">
                    Central resources
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Library
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        CIL
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Health center
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/computerCenter"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Computer center
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="/VisionforViksitBharat@2047/aboutViksitBharat"
                    className="sub-navlink-first bottom-menu-navLink-first"
                    // style={{ color: "blue", fontWeight: "bold" }}
                  >
                    Viksit Bharat @2047
                  </NavLink>
                </li>
               

                <li>
                  <NavLink
                    to="/7th_convocation"
                    className="sub-navlink-first bottom-menu-navLink-first"
                    
                  >
                    Convocation 
                  </NavLink> 
                </li>

                <li>
                  <NavLink
                    to="/"
                    className="sub-navlink-first bottom-menu-navLink-first"
                   
                  >
                    Accounts
                  </NavLink> 
                </li>


                {/* <li className="bottom-menu-first-li search-bar-li">
                  <div className="search-bar-box">
                    <input
                      type="search"
                      name="searchbar"
                      id="search-bar-nav"
                      placeholder="Search..."
                    />
                    <button className="search-bar-button">
                      <FaSearch />
                    </button>
                  </div>
                </li> */}
              </ul>
            </nav>
          </div>

          {/* Navigation  */}

          {/* <div className="last-img-section">
                        <a href="">
                            <img className='last-img' src={img6} alt="lastimg 1" />
                            <img className='last-img' src={img7} alt="lastimg 1" />
                            <img className='last-img' src={img8} alt="lastimg 1" />
                            <img className='last-img' src={img9} alt="lastimg 1" />
                        </a>
                    </div> */}
        </div>
        {/* upper header */}

        {/* <div className="upper-header">

                  <SideNav/>
                </div> */}

        {/* Responsive navigation for mobile */}

        {/* responsive navigation-button */}
        {respNavStatus ? (
          <div className="responsive-navigation-button-container">
            {" "}
            <button
              className="responsive-navigation-button"
              onClick={() => setRespNavStatus(!respNavStatus)}
            >
              <BiX />
            </button>
          </div>
        ) : (
          <div className="responsive-navigation-button-container">
            {" "}
            <button
              className="responsive-navigation-button"
              onClick={() => setRespNavStatus(!respNavStatus)}
            >
              <VscMenu />
            </button>
          </div>
        )}

        {respNavStatus ? (
          <div className="bottom-nav responsive-bottom-navigation">
            <nav className="navbar">
              <ul className="bottom-menu-ul">
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first bottom-menu-navLink-first-home-icon"
                    to="/"
                  >
                    <FaHome />
                  </NavLink>
                </li>
                <li className="bottom-menu-navLink-first">
                  <NavLink
                    to="/aboutUs"
                    className="sub-navlink-first bottom-menu-navLink-first"
                  >
                    {" "}
                    About us
                  </NavLink>
                  {/* <ul style={{width:"300%"}}>
                    <li>
                      <NavLink
                        to="/VisionforViksitBharat@2047"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Vision for Viksit Bharat @2047
                      </NavLink>
                      </li>
                      </ul> */}
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/rd">
                    R&D
                  </NavLink>
                </li>

                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/academics"
                  >
                    Academics
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Schools
                      </NavLink>
                      <ul>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/dipsar"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Delhi Institute of Pharmaceutical Sciences and
                            Reserch
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/soPharmaceticalSciences"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Pharmaceutical Sciences
                          </NavLink>
                          <ul style={{ left: "100%", width: "80px" }}>
                            <li className="inner-li-of-school-inside-of-academics">
                              {" "}
                              <NavLink
                                to="/ayurveda"
                                className="sub-navlink-first bottom-menu-navLink-first"
                              >
                                Ayurveda
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/sahsm"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Allied Health Sciences and Management
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/school-of-physiotherapy"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            School of Physiotherapy
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics">
                          {" "}
                          <NavLink
                            to="/doctoralPrograms"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Doctoral Programmes
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Centres
                      </NavLink>
                      <ul>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                          <NavLink
                            to="/wcsc"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            World Class Skill Center
                          </NavLink>
                        </li>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                        </li>
                        <li className="inner-li-of-school-inside-of-academics-centers">
                          {" "}
                          <NavLink
                            to="/assrm"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            ASSRM
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/centresOfExcellence"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Centres of Excellence
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/certificateCourses"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Certificate Courses
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href={academicSkillHub}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Skill Hub PMKVY 4.0
                      </a>
                    </li>
                    <li>
                      <a
                        href={academicCalender2425}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Academic calender-2024-25
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/administration"
                  >
                    Administration
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink
                        to="/genralCounsil"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Governing Board
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/staff"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Administrative Staff
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/organogram"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Organogram
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        to="/committees"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Committees
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/"
                  >
                    Admissions
                  </NavLink>
                
                  <ul>
                    <li>
                      <NavLink
                        to="/admissions2024_25"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                         Admissions 2024-25
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/admissions2023_24"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                    Admissions 2023-24
                      </NavLink>
                    </li>
                  </ul>




                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/students">
                    Students
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/scholarShips"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Scholarships
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/hostel"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Hostel
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/examination"
                  >
                    Examinations
                  </NavLink>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first">IQAC</NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/iaqcIntroduction"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Introduction
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacMoM"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        IQAC Committee (Composition and MoM)
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/QualityInitiativeIAQC"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Quality Initiatives
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/NationalRankingIAQC"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        National Rankings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/International-Accolades"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        International Accolades
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iaqcFeedback"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Feedback
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacEvent"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        IQAC Events
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/iqacPolicy"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contactUs"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first">
                    Accreditations & Approvals
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/naac"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NAAC
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href={naacAccreditationgradeseetjuly24}
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NAAC Accreditation Grade Sheet July 2024
                      </a>
                    </li>
                    <li>
                      <NavLink
                        to="/nirf"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        NIRF
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/atalRanking"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Atal Rankings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/approvals"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Approvals
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="/">
                    RTI
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink
                        to="/rtiAct"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        RTI Act
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="sub-navlink-first bottom-menu-navLink-first">
                        Section (4)(1)(b) of RTI Act
                      </NavLink>
                      <ul>
                        <li>
                          <NavLink
                            to="/organizationAndFunction"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Organization and Function
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/budgetAndProgramme"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Budget and Programme
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/ppiRti"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Publicity and Public Interface
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/eGovernance"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            E-Governance
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/informationAsMayBePrescribed"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Information as may be prescribed
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/informationDisclosedOnOwnInitiative"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Information Disclosed on own Initiative
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/annualReportOfRti"
                            className="sub-navlink-first bottom-menu-navLink-first"
                          >
                            Annual Report of RTI
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        to="/cpioAndFaa"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        CPIO and FAA
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* Events  */}
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/events
                    "
                  >
                    Events
                  </NavLink>

                </li>
                {/* Events  */}
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/campusAmenities"
                  >
                    Campus Amenities
                  </NavLink>
                </li>

                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/grievanceForm"
                  >
                    Grievance Form
                  </NavLink>
                </li>
                <li className="bottom-menu-first-li">
                  <NavLink
                    className="bottom-menu-navLink-first"
                    to="/dpsruLibrary"
                  >
                    Library
                  </NavLink>
                </li>

                <li className="bottom-menu-first-li">
                  <NavLink className="bottom-menu-navLink-first" to="">
                    Central resources
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Library
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        CIL
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/dpsruLibrary"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Health center
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/computerCenter"
                        className="sub-navlink-first bottom-menu-navLink-first"
                      >
                        Computer center
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li>
                  <NavLink
                    to="/VisionforViksitBharat@2047/aboutViksitBharat"
                    className="sub-navlink-first bottom-menu-navLink-first"
                    style={{ color: "blue", fontWeight: "bold" }}
                  >
                    Viksit Bharat @2047
                  </NavLink>
                </li>

               
                <li>
                  <NavLink
                    to="/7th_convocation"
                    className="sub-navlink-first bottom-menu-navLink-first"
                   
                  >
                    Convocation
                  </NavLink> 
                </li>
                <li>
                  <NavLink
                    to="/"
                    className="sub-navlink-first bottom-menu-navLink-first"
                   
                  >
                    Accounts
                  </NavLink> 
                </li>

                {/* search bar */}
                {/* <li className="bottom-menu-first-li search-bar-li">
                  <div className="search-bar-box ">
                    <input
                      type="search"
                      name="searchbar"
                      id="search-bar-nav"
                      placeholder="Search..."
                    />
                    <button className="search-bar-button">
                      <FaSearch />
                    </button>
                  </div>
                </li> */}
                {/* search bar */}
              </ul>
            </nav>
          </div>
        ) : null}

        {/* Responsive navigation */}
      </header>
    </div>
  );
}

export default Nav;
